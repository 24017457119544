import client from "./client";

const checkAnswer = async (wallet, answer) => {
  const response = await client.post("/question", {wallet: wallet, answer: answer});

  return response.data.correct === true;
};

const question = {
  checkAnswer
};

export default question;
